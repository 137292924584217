
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "cdnURL": "https://static.myproviders.org/static/787a33d92a24b67fa57959b1ce2b9d9e88f5d6c0",
  "version": "787a33d92a24b67fa57959b1ce2b9d9e88f5d6c0",
  "env": "prod",
  "firebase": {
    "apiKey": "AIzaSyBGm_Q6afNQMn3iuwTGCMfdqJbZI2qh55I",
    "authDomain": "id.myproviders.org",
    "projectId": "myproviders",
    "storageBucket": "myproviders.appspot.com",
    "messagingSenderId": "732261819013",
    "appId": "1:732261819013:web:4f1310f1f8a42421013fec",
    "measurementId": "G-YSBSMGBQJ3"
  },
  "searchIndex": "prod-search-6",
  "apiHost": "https://api.myproviders.org",
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
